import React from "react";
import "./topbar.css";
import MobileNavbar from "./MobileNavbar";

export default function Topbar() {
    return (
        <>
                        <MobileNavbar></MobileNavbar>
                        <div className="topbar hidden lg:block">
            <div className="topbarWrapper bg-white text-black">
                <div className="topLeft" />
                <div className="topLeft">
                    <a className="logo flex justify-center" href="/">
                        <div className="h-20 w-20 mx-auto overflow-hidden">
                            <img
                                className="w-full h-full object-cover object-top"
                                src="https://ik.imagekit.io/dnddecpho/longrich/longrichlogo_As_FxQXBm.png?updatedAt=1714729978154"
                                alt={""}
                            />
                        </div>
                    </a>
                </div>
                <div className="flex justify-center font-black">
                    <div className="topRight">
                        <div
                            className="marginRightSmall mr-10 cursor-pointer"
                            onClick={() => (window.location.href = "/")}
                        >
                            Home
                        </div>
                        <div
                            className="marginRightSmall mr-10 cursor-pointer"
                            onClick={() => (window.location.href = "/contact")}
                        >
                            Contact
                        </div>
                    </div>
                </div>
            </div>
        </div>
                        </>

    );
}
