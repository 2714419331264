import styled from "styled-components";
import { mobile, tablet } from "../responsive";

const Header = styled.div`
    height: 60vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    ${mobile({ height: "30vh" })}
    ${tablet({ height: "50vh" })}
`;

const Image = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    background-position: center center;
`;

const BannerOverlay = ({ imageUrl }) => { // Added destructuring for imageUrl prop
    return (
        <Header>
            <Image src={imageUrl} alt="About" /> {/* Removed extra spaces and placed closing tag properly */}
        </Header>
    );
};

export default BannerOverlay;
