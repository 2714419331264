import {
    MailOutline,
    Phone,
    Room,
    Facebook,
    Instagram,
} from "@material-ui/icons";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { Link } from "react-router-dom";

const Container = styled.div`
    padding: 20px 5rem;
    display: flex;
    background-size: cover;
    background-position: center;
    color: black;
    font-family: "FuturaPT-Demi";
    ${mobile({ flexDirection: "column", padding: "0px" })}
    ${tablet({ flexDirection: "column", padding: "0px" })}
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    ${mobile({
        padding: "2rem 3rem",
        justifyContent: "center",
        alignItems: "center",
    })}
    ${tablet({
        padding: "2rem 3rem",
        justifyContent: "center",
        alignItems: "center",
    })}
`;

const Logo = styled.img`
    max-height: 10vh;
    max-width: 10vh;
    ${mobile({ maxHeight: "15vh" })};
    ${tablet({ maxHeight: "15vh" })};
`;
const Desc = styled.p`
    margin: 20px 0px;
`;

const SocialContainer = styled.div`
    display: flex;
`;

const SocialIcon = styled.a`
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    background: black;
`;

const Center = styled.div`
    flex: 1;
    padding: 10px 20px;
    ${mobile({ display: "none" })}
    ${tablet({ display: "none" })}
`;

const Title = styled.h3`
    margin-bottom: 30px;
    ${mobile({ display: "none" })}
    ${tablet({ display: "none" })}
`;

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    flex-wrap: wrap;
`;

const Right = styled.div`
    flex: 1;
    padding: 10px 20px;
    ${mobile({ color: "black", padding: "3rem" })}
    ${tablet({ color: "black", padding: "3rem" })}
`;

const ContactItem = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
`;

const CopyrightContainer = styled.div`
    background: black;
    color: white;
    font-family: FuturaPT-Bold;
    padding: 20px 5rem;
    display: flex;
    justify-content: center;
    ${mobile({ flexDirection: "column", padding: "20px 3rem" })}
    ${tablet({ flexDirection: "column", padding: "20px 3rem" })}
`;

const Copyright = styled.div`
    text-align: center;
    font-size: 1rem;
    ${mobile({ fontSize: "0.8rem" })}
    ${tablet({ fontSize: "0.9rem" })}
`;

export const Footer = () => {
    return (
        <>
            <Container className="footer-background">
                <Left>
                    <Logo src="https://ik.imagekit.io/dnddecpho/longrich/longrichlogo_As_FxQXBm.png?updatedAt=1714729978154"></Logo>
                    <Desc>
                        LONGRICH TOUR & TRAVEL SDN BHD<br></br>
                        与长富旅游一起探索未知美景，融入文化与自然，尽享每一段无忧无虑的精彩旅程
                    </Desc>
                    <SocialContainer>
                        <SocialIcon
                            href="https://www.facebook.com/p/LongRich-Travel-100090624854639/"
                            target="blank"
                            color="3B5999"
                        >
                            <Facebook />
                        </SocialIcon>
                        <SocialIcon
                            href="https://www.instagram.com/longrich_travel/"
                            target="blank"
                            color="E4405F"
                        >
                            <Instagram />
                        </SocialIcon>
                    </SocialContainer>
                </Left>
                <Center>
                    <Title
                        style={{
                            fontSize: "1.2rem",
                            fontFamily: "FuturaPT-Bold",
                        }}
                    >
                        Links Menu
                    </Title>
                    <List>
                        <Link
                            style={{
                                textDecoration: "none",

                                marginBottom: "10px",
                            }}
                            to="/"
                        >
                            Home
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",

                                marginBottom: "10px",
                            }}
                            to="/contact"
                        >
                            Contact
                        </Link>
                    </List>
                </Center>
                <Right>
                    <Title
                        style={{
                            fontSize: "1.2rem",
                            fontFamily: "FuturaPT-Bold",
                        }}
                    >
                        Contact
                    </Title>
                    <ContactItem>
                        <Room style={{ marginRight: "10px" }} /> B-1-02, Residensi The Herz, Jalan 3/33a, Kepong Baru, 52100 Kuala Lumpur
                    </ContactItem>
                    <ContactItem>
                        <Phone style={{ marginRight: "10px" }} /> +6016 275 4311
                    </ContactItem>
                    <ContactItem>
                        <MailOutline style={{ marginRight: "10px" }} />{" "}
                        longrichtour@gmail.com
                    </ContactItem>
                </Right>
            </Container>
            <CopyrightContainer>
                <Copyright>© 2024 LONGRICH TOUR & TRAVEL SDN. BHD.</Copyright>
            </CopyrightContainer>
        </>
    );
};
